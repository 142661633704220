/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        //ScrollReveal().reveal('.headline');

        window.sr = new ScrollReveal();
        sr.reveal('.show-down', { 
          duration: 1300,
          origin: 'bottom',
          useDelay: 'always',
          distance: '30%',
          viewOffset: { top: 0, bottom: 100},
          reset: true,
          mobile: false
        }, 100);

        sr.reveal('.show-inThePoint', { 
          duration: 450,
          origin: 'bottom',
          viewOffset: { top: 0, bottom: 200},
          reset: true,
          mobile: false
        }, 100);

        sr.reveal('.show-right', { 
          duration: 1300,
          origin: 'right',
          useDelay: 'always',
          distance: '30%',
          reset: true,
          mobile: false
        }, 100);

        sr.reveal('.show-left', { 
          duration: 800,
          origin: 'left',
          useDelay: 'always',
          distance: '30%',
          reset: true,
          mobile: false
        }, 100);

        sr.reveal('.show-miniDown', { 
          duration: 1300,
          origin: 'bottom',
          viewOffset: { top: 0, bottom: 200},
          reset: true,
          mobile: false
        }, 100);


        sr.reveal('.show-left', { 
          duration: 1300,
          origin: 'left',
          distance: '20vh',
          reset: true,
          mobile: false
        });

        sr.reveal('.logo-down', {
          duration: 1300,
          origin: 'top',
          distance: '10vh',
          mobile: false
        });

        sr.reveal('.logo-up', {
          duration: 1300,
          origin: 'bottom',
          distance: '5vh',
          mobile: false
        });

        sr.reveal('.title-down', {
          origin: 'bottom',
          duration: 1300,
          reset: true,
          mobile: false
        }, 100);


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        $('.owl-carousel-homepage').owlCarousel({
            loop:false,
            margin:0,
            nav:false,
            autoplay:false,
            dots:false,
            responsive:{
                0:{
                    items:1
                },
                600:{
                    items:1
                },
                1000:{
                    items:1
                }
            }
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'acerca_de_omnivitalia': {
      init: function() {

        $('.owl-carousel-acercade').owlCarousel({
            loop:false,
            margin:0,
            nav:false,
            autoplay:false,
            dots:false,
            responsive:{
                0:{
                    items:1
                },
                600:{
                    items:1
                },
                1000:{
                    items:1
                }
            }
        });
        // JavaScript to be fired on the about us page
      }
    },
    // About us page, note the change from about-us to about_us.
    'single_proyectos': {
      init: function() {

        $('.slider-single').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          fade: false,
          adaptiveHeight: true,
          infinite: true,
          useTransform: true,
          autoplay: true,
          autoplaySpeed: 3000,
          speed: 400,
          cssEase: 'cubic-bezier(0.77, 0, 0.18, 1)',
          responsive: [{
            breakpoint: 1024,
            settings: {
              
            }
          }, {
            breakpoint: 640,
            settings: {
              arrows: false,
            }
          }, {
            breakpoint: 420,
            settings: {
              arrows: false,
            }
          }]
        });
        $('.slider-nav')
        .on('init', function(event, slick) {
          $('.slider-nav .slick-slide.slick-current').addClass('is-active');
        })
        .slick({
          slidesToShow: 5,
          slidesToScroll: 5,
          arrows: false,
          dots: false,
          focusOnSelect: false,
          infinite: true,
          responsive: [{
            breakpoint: 1024,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5,
            }
          }, {
            breakpoint: 640,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            }
          }, {
            breakpoint: 420,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            }
          }]
        });
        $('.slider-single').on('afterChange', function(event, slick, currentSlide) {
          $('.slider-nav').slick('slickGoTo', currentSlide);
          var currrentNavSlideElem = '.slider-nav .slick-slide[data-slick-index="' + currentSlide + '"]';
          $('.slider-nav .slick-slide.is-active').removeClass('is-active');
          $(currrentNavSlideElem).addClass('is-active');
        });
        $('.slider-nav').on('click', '.slick-slide', function(event) {
          event.preventDefault();
          var goToSingleSlide = $(this).data('slick-index');
          $('.slider-single').slick('slickGoTo', goToSingleSlide);
        });
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
